import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
const theme = createTheme(adaptV4Theme({
    palette: {
        // mode:"dark",
        // primary: {
        //     light: '#58a5f0',
        //     main: '#0277bd',
        //     dark: '#004c8c',
        // },
        // secondary: {
        //     light: '#ffd95a',
        //     main: '#f9a825',
        //     dark: '#c17900',
        //     contrastText: '#212121',
        // },
        // background: {
        //     default: '#f0f0f0',
        // },
    },
    typography: {
        useNextVariants: true,
    },
}));

ReactDOM.render(
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
        <CssBaseline />
<App />
</ThemeProvider>
         </StyledEngineProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
