import React from 'react';
import { BrowserRouter,Routes, Route,useNavigate } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';

import PrivateRoute from './Utilities/private-route';
import Home from './Home/Home';
import Chat from './Chat/Chat';


function App() {
    return (
     
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <BrowserRouter >
                        <Routes>
                        <Route path="/" exact element={<Home/>} />
                        <Route path="/chat" 
                        element={
                        <PrivateRoute redirectTo="/">
                            <Chat/>
                        </PrivateRoute>
                            }
                             />
                        <Route path="*" element={'404'} />
                        </Routes>
                    </BrowserRouter>
                </SnackbarProvider>
        
    );
}

export default App;
