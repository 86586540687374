import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import clsx from 'clsx';
import Header from '../Layout/Header';
import MenuBar from '../Chat/MenuBar'
import ChatBox from './ChatBox';
import Conversations from './Conversations';
import useMediaQuery from '@mui/material/useMediaQuery';
import Users from './Users';

import './chat.css'
import { DialogContent } from '@mui/material';
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: '100vh',
        borderRadius: 0,
    },
    sidebar: {
        zIndex: 8,
    },
    subheader: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    globe: {
        backgroundColor: theme.palette.primary.dark,
    },
    subheaderText: {
        color: theme.palette.primary.dark,
    },
    colorBg:{
        backgroundColor: "#74EBD5",
        backgroundImage: "linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%)"
    }
}));

const Chat = () => {
    const [scope, setScope] = useState(null);
    const [tab, setTab] = useState(0);
    const [user, setUser] = useState(null);
    const [showUsers,setShowUsers]=useState(false)
    const classes = useStyles();

    const handleChange = (e, newVal) => {
        setTab(newVal);
    };
    

  const matches = useMediaQuery('(max-width: 480px)');
  
    
  console.log('matches',matches)

    return (
        <>
            {/* <Header /> */}
            
            <Grid container spacing={0.25}  wrap='nowrap' sx={{ height: '100%',width:'100%', top: 0, bottom:0,position:"fixed",overflowY:"hidden",overflowX:"hidden" }}>
            {!matches&&<Grid  item md={4}  className={classes.sidebar}>
             
                    <Paper className={classes.paper}  elevation={1} square>
                        {/* <MenuBar 
                        setUser={setUser} setScope={setScope}
                        setShowUsers={setShowUsers}

                        /> */}
                        <Paper square>
                            <Tabs
                                onChange={handleChange}
                                variant="fullWidth"
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Chats" />
                                <Tab label="Users" />
                            </Tabs>
                        </Paper>
                        {tab === 0 && (
                            <Conversations
                                setUser={setUser}
                                setScope={setScope}
                            />
                                )} 
                        {tab === 1 && (
                           <Users setUser={setUser} setScope={setScope} />
                        )}
                          {/* {showUsers&&
                          <Dialog onClose={()=>setShowUsers(false)} open={showUsers}maxWidth={'xs'}fullWidth>
                              <DialogContent dividers={'paper'}style={{height:'600px'}}>
                              <Users setUser={setUser} setScope={setScope} />
                              </DialogContent>
                          
                          </Dialog>
                            
                        } */}

                           
                    </Paper>
                    
                </Grid>
}
               
               {scope?
                <Grid item md={8} sx={{ height: '100%', maxWidth: 'md' }}>
                <ChatBox scope={scope} user={user} matches={matches}/>
                </Grid> 
                :   
                <Grid item md={8} sx={{ height: '100%', maxWidth: 'md' }}>
                 <Paper className={clsx(classes.paper,classes.colorBg)} sx={{ width: '100%' }} square elevation={2}></Paper>
                 </Grid>
}
            </Grid>
        </>
    );
};

export default Chat;

