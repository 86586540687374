import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';

import Login from './Login';
import Register from './Register';
import { authenticationService } from '../Services/authenticationService';
import { useNavigate } from 'react-router-dom';

const Home = (props) => {
    const [page, setPage] = useState('login');
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticationService.currentUserValue) {
           navigate('chat')
        }
    }, []);

    const handleClick = location => {
        setPage(location);
    };

    let Content;

    if (page === 'login') {
        Content = <Login handleClick={handleClick} />;
    } else {
        Content = <Register handleClick={handleClick} />;
    }

    return (
        <Container component="main" maxWidth="xs">
            {Content}
        </Container>
    );
};

export default Home;
