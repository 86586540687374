import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LanguageIcon from "@mui/icons-material/Language";
import Divider from "@mui/material/Divider";
import makeStyles from '@mui/styles/makeStyles';
import {io} from "socket.io-client";

import { useGetConversations } from "../Services/chatService";
import { authenticationService } from "../Services/authenticationService";
import commonUtilites from "../Utilities/common";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  subheader: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    backgroundColor: theme.palette.primary.dark,
  },
  subheaderText: {
    color: theme.palette.primary.dark,
  },
  list: {
    maxHeight: "calc(100vh - 112px)",
    overflowY: "auto",
  },
}));

const Conversations = (props) => {
  const classes = useStyles();
  const [conversations, setConversations] = useState([]);
  const [newConversation, setNewConversation] = useState(null);
  const getConversations = useGetConversations();

  // Returns the recipient name that does not
  // belong to the current user.
  const handleRecipient = (recipients) => {
    var recepit={}
    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].username !== authenticationService.currentUserValue.username) {
        console.log('recipients[i];',recipients[i])
        recepit = recipients[i];
      }
    }
    return recepit
    // return null;
  };
const nameRecipit=(recipients)=>{
  // console.log('recipients',recipients)
  var recepit={}
  for (let i = 0; i < recipients.length; i++) {
    // console.log('recipients',recipients[i],i)
    if (recipients[i].username !== authenticationService.currentUserValue.username) {
      recepit= recipients[i];
    }
  }
  return recepit
}
  useEffect(() => {
    getConversations().then((res) => setConversations(res));
  }, [newConversation]);

  useEffect(() => {
    let socket = io(process.env.REACT_APP_WSS);
    socket.on("messages", (data) => setNewConversation(data));

    return () => {
      socket.removeListener("messages");
    };
  }, []);
console.log('conversations',conversations)
  return (
    <List className={classes.list}>
      <ListItem
        classes={{ root: classes.subheader }}
        onClick={() => {
          props.setScope("Global Chat");
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.globe}>
            <LanguageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.subheaderText} primary="Global Chat" />
      </ListItem>
      <Divider />

      {conversations && (
        <React.Fragment>
          
          {conversations.map((c) => (
           
            <ListItem
            
            className={classes.listItem}
              key={c._id}
              button
              onClick={() => {
                let value = handleRecipient(c.recipientObj)
                props.setUser(value);
                props.setScope(value.name);
              
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  {commonUtilites.getInitialsFromName(
                    nameRecipit(c.recipientObj).name
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={nameRecipit(c.recipientObj).name}
                secondary={<React.Fragment>{c.lastMessage}</React.Fragment>}
              />
            </ListItem>
          ))}
        </React.Fragment>
      )}
    </List>
  );
};

export default Conversations;
